$(function() {
    
    let {NODE_ENV, API_TOKEN} = process.env;
    if(NODE_ENV == "local"){
        require('dotenv').config({ path: '.env.local' })
    }else if(NODE_ENV == "development"){
        require('dotenv').config({ path: '.env.development' })
    }else if(NODE_ENV == "production"){
        require('dotenv').config({ path: '.env.production' })
    }
    console.log(NODE_ENV)

    "use strict";

    //===== Prealoder

    $(window).on('load', function (event) {
        $('.preloader').delay(500).fadeOut(500);
    });


    //===== Sticky

    $(window).on('scroll', function (event) {
        var scroll = $(window).scrollTop();
        if (scroll < 20) {
            $(".header_navbar").removeClass("sticky");
            $(".header_navbar img").attr("src", "assets/images/logo.png");
        } else {
            $(".header_navbar").addClass("sticky");
            $(".header_navbar img").attr("src", "assets/images/logo-dark.png");
        }
    });


    //===== Section Menu Active

    var scrollLink = $('.page-scroll');
    // Active link switching
    $(window).scroll(function () {
        var scrollbarLocation = $(this).scrollTop();

        scrollLink.each(function () {

            var sectionOffset = $(this.hash).offset().top - 73;

            if (sectionOffset <= scrollbarLocation) {
                $(this).parent().addClass('active');
                $(this).parent().siblings().removeClass('active');
            }
        });
    });
    

    //===== close navbar-collapse when a  clicked

    $(".navbar-nav a").on('click', function () {
        $(".navbar-collapse").removeClass("show");
    });

    $(".navbar-toggler").on('click', function () {
        $(this).toggleClass("active");
        $(".navbar-collapse").toggleClass("show");
    });

    $(".navbar-nav a").on('click', function () {
        $(".navbar-toggler").removeClass('active');
    });
    

    //====== slick

    //====== Magnific Popup


    //===== Back to top

    // Show or hide the sticky footer button
    $(window).on('scroll', function (event) {
        if ($(this).scrollTop() > 600) {
            $('.back-to-top').fadeIn(200)
        } else {
            $('.back-to-top').fadeOut(200)
        }
    });


    //Animate the scroll to yop
    $('.back-to-top').on('click', function (event) {
        event.preventDefault();

        $('html, body').animate({
            scrollTop: 0,
        }, 1500);
    });


    //=====  WOW active

    var wow = new WOW({
        boxClass: 'wow', //
        mobile: false, // 
    })
    wow.init();

    //===== 

    //LOAD EVENTS FROM API
    loadEventsFromApi();
    function loadEventsFromApi(){
        const parser = new DOMParser();

        const templateEventHtml = `
        <div>
            <h4 style="margin-top: 20px;" data-event-title=""></h4>
            <div style="display: flex; flex-direction: column; gap: 5px; margin-top: 10px;" data-events-entries="">
            </div>
        </div>`

        const templateEventEntrieHtml =  `                               
            <span style="display: grid;"></span>
            `




        fetch(process.env.API_URL + "/kurse")
            .then(res => {
                return res.json();
            })
            .then(data => {

                if(data && data.length > 0){
                    data.forEach(kurs => {
                        const month = ["Jänner","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember"];
                        const d = new Date(kurs.date);

                        const events = parser.parseFromString(templateEventHtml, 'text/xml');
                        events.querySelector("[data-event-title]").innerHTML = "Kurs " +month[d.getMonth()];
                        const eventsEntriesContainer = events.querySelector("[data-events-entries]");
                        kurs.termine.forEach( termin => {
                            const eventsEntrie = parser.parseFromString(templateEventEntrieHtml, 'text/xml');
                            eventsEntrie.querySelector("span").innerHTML = getDayName(termin.date, "de-DE") + " " + getdhm(termin.date) + " von " + termin.starttime + "-" + termin.endtime + "<small><b>" + termin.groupname + "</b></small>";
                            eventsEntriesContainer.append(eventsEntrie.firstChild)
                        })

                        document.getElementById("events-container").append(events.firstChild)


                        function getDayName(dateStr, locale){
                            var date = new Date(dateStr);
                            return date.toLocaleDateString(locale, { weekday: 'long' });        
                        }

                        function getdhm(timestamp) {
                            var date = new Date(timestamp);
                            var month = date.getMonth()+1;
                            var day = date.getDate();
                            
                            if(day < 10){
                                day = "0" + day;
                            }

                            if(month < 10){
                                month = "0" + month;
                            }
                    
                            var formattedTime = day + "." + month;
                            return formattedTime;
                    
                        }

                    });
                    

                }


                return data;
            })
            .catch(e => {
                console.log(e);
                return e;
            });
    }



});
